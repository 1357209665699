<div class="small-player-container">
  <button class="btn btn-warning me-2 p-2 radius-100 d-inline-flex align-items-center px-3 play-button"
  aria-label="Play Button" (click)='mainService.toggleStreamPlayback()'>
    <span *ngIf='!mainService.isStreamPlaying' class="btn btn-outline-light me-2 py-2 rounded-circle d-inline-flex"><i class="ri-play-fill"></i></span>
    <span *ngIf='mainService.isStreamPlaying' class="btn btn-outline-light me-2 py-2 rounded-circle d-inline-flex"><i class="ri-pause-fill"></i></span>
    <div class="d-flex flex-column align-items-start">
      <span class="fw-bold">Now playing</span>
      <div class="mainMarqueeDiv">
        <span class="text-capitalize text-start marqueeWrapper"><span>{{ mainService.currentArtist }}</span>-{{ mainService.currentSong }}</span>
      </div>
    </div>
  </button>
  <div class="volume-container">
    <img class="volume-icon" src="assets/images/volume-icon.png" alt="volume-icon" />
    <input type="range" min="0" max="100" class="volume-slider slider-progress" (input)="volumeSliderChanged($event)" [style.--min]="minVolume" [style.--max]="maxVolume" [style.--value]="volumeValue">
  </div>
</div>



<style>
.mainMarqueeDiv{
  overflow: hidden;
  white-space: nowrap;
  width: 100px;
}

.marqueeWrapper {
  display: inline-block;
  animation: marquee 7s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(5%);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
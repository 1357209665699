import { Injectable, EventEmitter } from '@angular/core';
import { io } from "socket.io-client";
import { webSocket } from "rxjs/webSocket";


@Injectable({
    providedIn: 'root'
})
export class MainService {

    public currentArtist = 'PLAY RADIO';
    public currentSong = 'LIVE';
    public showStreamQualitySettings = false;
    public playerThrottle = false;
    public isStreamPlaying = false;
    public websocketUrlPlayMontenegro = 'wss://playradio.me:21818/ws';
    public websocketUrlUrban = 'wss://playradio.rs:21712/ws';
    public websocketUrlSoft = 'wss://playradio.rs:21711/ws';
    public websocketUrlRock = 'wss://playradio.rs:21714/ws';
    public websocketUrlParty = 'wss://playradio.rs:21713/ws';
    public websocketUrlBalkan = 'wss://playradio.rs:21715/ws';
    public websocketSubject = null;
    public currentAudioVolume = 50;

    public audioStreamStateChanged: EventEmitter<any> = new EventEmitter();
    public streamTypeChanged: EventEmitter<any> = new EventEmitter();
    public audioVolumeChanged: EventEmitter<any> = new EventEmitter();

    constructor() { }

    startSocketListener() {
        var websocketUrl = this.getWebSocketUrl();
        this.websocketSubject = webSocket(websocketUrl);

        this.websocketSubject.subscribe(
            (msg) => {
                // console.log('message received: ' + JSON.stringify(msg)); // Called whenever there is a message from the server.
                if (msg['nowPlaying']['artist']) {
                    this.currentArtist = msg['nowPlaying']['artist'];
                    this.currentSong = msg['nowPlaying']['title'];
                }
            },
            err => console.log('websocket error', err), // Called if at any point WebSocket API signals some kind of error.
            () => {
                // console.log('websocket complete') // Called when connection is closed (for whatever reason).
            }
        );
    }

    stopSocketListener() {
        this.websocketSubject.unsubscribe();
    }

    toggleStreamQualitySettings() {
        this.showStreamQualitySettings = !this.showStreamQualitySettings;
    }

    toggleStreamPlayback() {
        if (this.playerThrottle === true) {
            return;
        }

        this.playerThrottle = true;

        setTimeout(() => {
            this.isStreamPlaying = !this.isStreamPlaying;
            this.audioStreamStateChanged.emit(this.isStreamPlaying);
            this.playerThrottle = false;
        }, 500);
    }

    stopStreamPlayback() {
        this.isStreamPlaying = false;
        this.audioStreamStateChanged.emit(this.isStreamPlaying);
    }

    startStreamPlayback() {
        this.isStreamPlaying = true
        this.audioStreamStateChanged.emit(this.isStreamPlaying);
    }

    getStreamType() {
        var streamType = localStorage.getItem('stream_type');
        if (!streamType) {
            streamType = 'play';
        }

        return streamType;
    }

    setStreamType(streamType) {
        localStorage.setItem('stream_type', streamType);
    }

    getWebSocketUrl() {
        var selectedStreamType = this.getStreamType();

        switch(selectedStreamType) {
            case 'urban':
                return this.websocketUrlUrban;
            case 'soft':
                return this.websocketUrlSoft;
            case 'rock':
                return this.websocketUrlRock;
            case 'party':
                return this.websocketUrlParty;
            case 'balkan':
                return this.websocketUrlBalkan;
            default:
                return this.websocketUrlPlayMontenegro;
        }
    }
}



